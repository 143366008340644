
import { bigDataService, academyService, eLearningService } from "@/_services";
import { IPageResponse, IPermission } from "@/_utils/types";
import IconSVG from "@/components/IconSVG.vue";
import {
  defineComponent,
  UnwrapRef,
  ref,
  reactive,
  watch,
  toRefs,
  toRef,
  computed,
  toRaw,
} from "vue";
import { useRequest } from "vue-request";
import { CheckCircleOutlined } from '@ant-design/icons-vue';
interface FormState {
  email: string;
  permission?: string;
}
interface SelectorState {
  selectedValue:
    | {
        key: string;
        value: string;
        label: string;
      }
    | undefined;
  data: {
    text: string;
    value: string;
    email: string;
    citizenId: string;
  }[];
  fetching: boolean;
}
const defaultPermission = undefined;
export default defineComponent({
  props: {
    visible: Boolean,
    type: {
      type: String,
      validator: (val: string) =>
        ["bigData", "academy", "eLearning"].includes(val),
    },
    accountId: {
      type: Number,
      required: false,
    },
  },
  components: {
    IconSVG,
    CheckCircleOutlined
  },
  setup(props, { emit }) {
    const type = toRef(props, "type");
    const service =
      type.value === "bigData"
        ? bigDataService
        : type.value === "academy"
        ? academyService
        : eLearningService;
    const accountId = toRef(props, "accountId");
    const isVisible = toRef(props, "visible");
    const selectedAccountId = ref(accountId.value);
    const isChangedPermission = ref<boolean>(false);
    const permissionOptions = ref<IPermission[]>([]); //reactive<{value: IPermission[]}>({value:[]})
    const defaultPermissionId = ref(-1);
    const formRef = ref();
    const errorMessage = ref<string>("");
    const isSentEmail = ref<boolean>(false);
    const isChangedToDefalut = ref(false);
    const disableSelectPerm = ref<boolean>(false)
    const formState: UnwrapRef<FormState> = reactive({
      email: "",
      permission: defaultPermission,
    });
    const state = reactive<UnwrapRef<SelectorState>>({
      data: [],
      selectedValue: undefined,
      fetching: false,
    });
    const isVerifiedEmail = ref<boolean>(false);
    watch([isVisible, accountId], () => {
      if(isVisible.value){
        if (accountId.value) {
          selectedAccountId.value = accountId.value;
          // fetchUsers({page: 1, account_id: accountId.value})
        } else {
          fetchUsers({ page: 1, size: 10 });
        }
      }
    })

    watch(selectedAccountId, () => {
      if (selectedAccountId.value) {
        fetchUsers({ page: 1, account_id: selectedAccountId.value });
      }
    });

    const onSelectedUser = (selected: any, option: never) => {
      if (+selected.value) {
        selectedAccountId.value = +selected.value;
        // fetchUsers({page: 1, account_id: +selected.value})
      }
      // formState.email = option["data-email"];
    };

    const handleFormFinish = () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // const selectedUser = toRaw(state.selectedValue) as any
      const permissionId = formState?.permission ?? '';

      if (+permissionId === +defaultPermissionId.value) {
        isChangedToDefalut.value = true
      }
      const userId = state.selectedValue?.value;
      errorMessage.value = "";
      if (permissionId && userId) {
        updateUser(+userId, +permissionId);
      }
    };
    const dismissForm = () => {
      const isSave = isSentEmail.value
      formState.email = "";
      formState.permission = defaultPermission;
      if (formRef.value) formRef.value.resetFields();
      state.selectedValue = undefined;
      isChangedPermission.value = false;
      isChangedToDefalut.value = false
      isSentEmail.value = false;
      errorMessage.value = "";
      selectedAccountId.value = -1;
      emit("closeModal", isSave);
    };

    const { data: permissions } = useRequest(service.getPermissions, {
      ready: computed(() => !!type.value),
      onSuccess: () => {
        const { data } = permissions.value?.data as IPageResponse<IPermission[]>;
        if (data) {
          permissionOptions.value = data;
          const defaultPerm = data.find(ele => ele.permission_code.includes('DEFAULT'))
          defaultPermissionId.value = defaultPerm?.id ?? -1
        }
      },
    });

    const onFetchSuccess = () => {
      if (result.value?.data) {
        const users = result.value?.data;
        const options = users.data.map((user) => {
          const { account_informations, account_id, account_email } =
            user.accounts;
          const {
            account_information_firstname_en: fEn,
            account_information_firstname_th: fTh,
            account_information_lastname_en: lEn,
            account_information_lastname_th: lTh,
            account_information_citizen_id: citizenId,
          } = account_informations;
          return {
            text: `${(fEn || fTh) ?? "N/A"} ${
              fEn ? lEn ?? "N/A" : lTh ?? "N/A"
            }`,
            value: `${account_id}`,
            email: account_email,
            citizenId,
          };
        });
        state.data = options;
        if (selectedAccountId.value) {
          const focusedUser = users.data
            .map((e) => e.accounts)
            .find((ele) => +ele.account_id === selectedAccountId.value);
          const option = state.data.find(
            (ele) => ele.value === `${selectedAccountId.value}`
          );
          if (option) {
            state.selectedValue = {
              key: `${option.value}`,
              label: option.text,
              value: option.value,
            };
            formState.email = option.email;
          }
          if (focusedUser) {
            const roles = (users.data[0] as any).role_permission.permissions;
            formState.permission = roles.id;
            isChangedPermission.value = !focusedUser.account_email_verified;
            isVerifiedEmail.value = focusedUser.account_email_verified;
            disableSelectPerm.value = isChangedPermission.value && !isVerifiedEmail.value
            // Every permission menu is false
            if(Object.values(roles.permission_menu).every(val => val === false)){
              disableSelectPerm.value = false
            }
            // isSentEmail.value = true //show modal email sent
          }
        }
      } else {
        state.data = []
      }
      state.fetching = false;
    };

    const {
      data: result,
      loading: fetchingUsers,
      run: fetchUsers,
    } = useRequest(service.getAccounts, {
      debounceInterval: 300,
      // ready: computed(() => type.value === 'bigData'),
      defaultParams: [{ page: 1, size: 10 }],
      onSuccess: onFetchSuccess,
    });

    const { loading: updatingUser, run: updateUser } = useRequest(
      service.updatePermissionAccount,
      {
        manual: true,
        onSuccess: (result) => {
          // isChangedPermission.value = true
          if (accountId.value) {
            // runSendConfirmEmail(accountId.value);
            isSentEmail.value = true
          }
        },
        onError: (error) => {
          const { message } = error;
          if (message) {
            errorMessage.value = message;
          }
        },
      }
    );

    const { run: runSendConfirmEmail, loading: sendingEmail } = useRequest(
      service.resendEmail,
      {
        manual: true,
        onSuccess: (result) => {
          const { success } = result;
          if (success) {
            isSentEmail.value = true
          }
          //
          // dismissForm
        },
      }
    );

    const sendConfirmEmail = () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const selectedUser = toRaw(state.selectedValue) as any;
      runSendConfirmEmail(selectedUser.value);
    };

    const fetchUser = (value: string) => {
      state.fetching = true;
      state.data = [];
      fetchUsers({
        page: 1,
        size: 5,
        name: value,
      });
    };

    return {
      formRef,
      formState,
      ...toRefs(state),
      fetchUser,
      fetchingUsers,
      onSelectedUser,
      handleFormFinish,
      dismissForm,
      permissionOptions,
      updatingUser,
      isChangedPermission,
      sendConfirmEmail,
      sendingEmail,
      errorMessage,
      isSentEmail,
      isVerifiedEmail,
      disableSelectPerm,
      isChangedToDefalut
    };
  },
});
